import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Layout from "../../../Components/layouts";
import Typography from '@material-ui/core/Typography';
import GenericTable from '../../../Components/GenericTable';
import { Pagination } from '@material-ui/lab';
import { Paths } from 'Routes/routePaths';
import OutlinedButton from 'Components/OutlinedButton';
import { ExperimentServices } from 'Services/ExperimentServices';
import LockIcon from '@material-ui/icons/Lock';
import Icon from '@material-ui/core/Icon';
import CodeIcon from '@material-ui/icons/Code';
import SearchBoxInput from 'Components/SearchBoxInput';
import { useHistory } from 'react-router';
import commonStyles from 'theme/commonStyles';

const experimentService = new ExperimentServices();

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: 180,
        background: '#FFFFFF',
        boxShadow: "0px 4px 30px rgba(12, 41, 84, 0.1)",
        borderRadius: 5
    },
    pageTitle: {
        marginBottom: 32,
    }
}));

const columns = [
    { label: "Lab", key: 'name' },
    { label: "Description", key: 'description' },
    { label: "Hours", key: 'duration' },
    { label: "", key: 'status' }
];

const Experiments = () => {

    const classes = useStyles();
    const history = useHistory();
    const commonClasses = commonStyles();

    const [filterState, setFilterState] = React.useState({ page: 1, search: "" });
    const [experimentList, setExperimentList] = React.useState([]);
    const [pagination, setPagination] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        getAllExperiments(filterState);
    }, []);

    const getAllExperiments = async (queryData) => {
        let query = `?search=${queryData.search}&page=${queryData.page}`;
        const experiments = await experimentService.getAllExperiment(query);
        if (experiments && experiments.results) {
            console.log("experiments.results: ", experiments.results);

            setExperimentList(experiments.results.map(item => {
                return {
                    ...item,
                    name: (
                        <Grid item>
                            <Icon
                                component={() => (
                                    <CodeIcon
                                        style={{ marginTop: '2px', marginRight: '6px' }}
                                    />
                                )}
                                alt={'icon'}
                            />
                        </Grid>
                    ),
                    status: (<Grid item>
                            <Icon
                            component={() => (
                                <LockIcon
                                    style={{ marginTop: '2px', marginRight: '6px' }}
                                />
                            )}
                            alt={'icon'}
                        />
                    </Grid>)
                }
            }));
            setPagination(experiments.pagination);
        }
        setLoading(false);
    };

    const handlePageChange = (evt, page) => {
        setFilterState({ ...filterState, page });
        return getAllExperiments({ ...filterState, page });
    };

    const handleSearch = e => {
        e.preventDefault();
        setLoading(true);
        return getAllExperiments({ ...filterState, page: 1 });
    };

    const handleItemClick = (row, action) => {
        if (!row) return null;
        
        history.push(Paths.AddExperiment + '/' + row.uuid)
    }

    const handleCreateNew = () => {
        history.push(Paths.AddExperiment);
    }


    return (
        <Layout>
            <main>
                <div className={commonClasses.contentWrapper}>
                    <Typography variant="h4" className={classes.pageTitle}>My Experiment</Typography>
                    <Grid container justifyContent={'space-between'}>
                        <Grid item>
                            <SearchBoxInput 
                                value={filterState.search} 
                                handleChange={(evt) => {
                                    setFilterState({ ...filterState, search: evt.target.value })
                                    return getAllExperiments({ search: evt.target.value, page: 1 })
                                }}
                                handleSearch={handleSearch} 
                            />
                        </Grid>
                        <Grid item>
                            <OutlinedButton 
                                title={"Create Experiment"} 
                                handleClick={handleCreateNew} 
                                align={'right'} 
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: 20 }}>
                        <GenericTable
                            columns={columns}
                            loading={loading}
                            handleItemClick={handleItemClick}
                            list={experimentList}
                        />
                    </Grid>
                    <Grid container style={{ marginTop: 22 }} >
                        {pagination &&
                            <Pagination
                                onChange={handlePageChange}
                                style={{ margin: "0 auto" }}
                                count={parseInt(Math.ceil(pagination.total_entries / pagination.end_index))}
                                variant="outlined"
                                shape="rounded"
                                siblingCount={0}
                            />}
                    </Grid>
                </div>
            </main>
        </Layout>
    );
}

export default Experiments;