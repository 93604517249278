import { authService } from "Services/AuthService";
import { getDeep } from "utils";
import { ERROR_MESSAGE, USER_ROLES } from "../utils/constants";

const doLoginApi = (user) => {
  return authService
    .login(user.username, user.password)
    .then(response => {
      console.log("response: ", response);
      if (response.status) {
        const message = {...response.data};
        const user_auth = {
          ...message,
          isLogin: true,
          user: user.username,
          company_id: message.customers ? message.customers[0] : null,
          company_logo: message.company_logo ? message.company_logo : null
        };
        localStorage.setItem('user_auth', JSON.stringify(user_auth));
        localStorage.setItem('user', user.username);
        localStorage.setItem('isLogin', 'true');
        localStorage.setItem('uuid', message.uuid);
        localStorage.setItem('company_name', message.company_name);
        localStorage.setItem(
          'company_id',
          message.customers ? message.customers[0] : 'null'
        );
        localStorage.setItem('SSO', message.SSO);
        localStorage.setItem('ssoToken', message.token);
        localStorage.setItem(
          'apprenticeship',
          getDeep(message, 'registered.apprenticeship', 0)
        );
        localStorage.setItem('session_data', message.session_data);
        localStorage.setItem('IDP:SSOToken', message['IDP:SSOToken']);
        localStorage.setItem('role', message.role);
        localStorage.setItem('sub_role', message.sub_role);
        // localStorage.setItem(
        //   'company_logo',
        //   message.company_logo ? message.company_logo : 'null'
        // );
        localStorage.setItem('identityToken', message.identity_token);
        const pr = message.project_id || false;
        if (pr) {
          localStorage.setItem('project_id', pr);
        }
        return {...response, role: response?.role || USER_ROLES.USER };
      } else {
        return response;
      }
    })
    .catch(function (error) {
      //no data
      console.log(error);
      throw error;
    });
};

export async function loginUser(dispatch, loginPayload) {
  try {
    dispatch({ type: 'REQUEST_LOGIN' });
    const data = await doLoginApi(loginPayload);
    if (data.status && data.data) {
      console.log("data.data: ", data.data);
      localStorage.setItem('currentUser', JSON.stringify(data.data));
      dispatch({ type: 'LOGIN_SUCCESS', payload: data.data });
      return data;
    }
    throw ERROR_MESSAGE.INVALID_CRED;
  } catch (error) {
    dispatch({ type: 'LOGIN_ERROR', error: error });
  }
}

export async function logout(dispatch) {
  localStorage.clear();
  dispatch({ type: 'LOGOUT' });
}