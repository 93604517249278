import React, { useEffect, useState } from 'react';
import { Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';
import { Paths, UserPath } from './routePaths';
import Login from '../Pages/Login';
import DashBoard from '../Pages/DashBoard';
import PageNotFound from '../Pages/PageNotFound';
import Administrators from '../Pages/Administrators';
import Customers from '../Pages/SuperAdmin/Customers';
import CreateCustomer from '../Pages/SuperAdmin/Customers/Create';
import Users from '../Pages/Users';
import Courses from 'Pages/Courses';
import AddUsers from '../Pages/Users/addUsers'
import AddAdministrator from '../Pages/Administrators/addAdministrator'
import AddCourses from '../Pages/Courses/addCourses'
import UpdateUsers from '../Pages/Users/updateUsers';
import Profile from '../Pages/Profile'
import Licenses from '../Pages/Licenses'
import MyAssesssments from '../Pages/MyAssesssments'
import { AuthProvider } from '../Context';
import AppRoutes from './AppRoutes';
import Reporting from '../Pages/Reporting';
import LearningService from '../Pages/Reporting/LearningService';
import Calendar from '../Pages/Calendar';
import DigitalReadliness from '../Pages/Reporting/DigitalReadliness';
import UserDashboard from '../Pages/UserAdmin/UserDashboard';
import Assessments from '../Pages/UserAdmin/Assessments';
import ExperimentAndTesting from '../Pages/UserAdmin/ExperimentAndTesting';
import CourseCatalogue from '../Pages/UserAdmin/SkillTransformation/CourseCatalogue';
import RegisteredCourses from '../Pages/UserAdmin/SkillTransformation/RegisteredCourses';
import VirtualGlobalApprenticeship from '../Pages/UserAdmin/SkillTransformation/VirtualGlobalApprenticeship';
import ChangePassword from '../Pages/Profile/ChangePassword';
import Deployments from '../Pages/Deployments';
import AddDeployment from '../Pages/Deployments/addDeployment';
import CustomerOnBoarding from '../Pages/CustomerOnBoarding';
import Experiments from '../Pages/SuperAdmin/Experiments';
import AddExperiment from '../Pages/SuperAdmin/Experiments/AddExperiment';
import ThemeEditor from '../Pages/ThemeEditor';
import { authService } from 'Services/AuthService';
import { resetThemeColors } from '../utils';
import CourseRequests from 'Pages/CourseRequests';
import ForgotPassword from 'Pages/ForgotPassword';
import WorkReadiness from 'Pages/UserAdmin/SkillTransformation/WorkReadiness';
import CourseProvider from 'Context/CourseProvider';
import { PLATFORM_HOST_NAME } from 'utils/constants';

const Routes = () => {
    
    const [hide, setHide] = useState(false);
    const routes = [
        {
            path: Paths.Login,
            component: Login,
            isPrivate: false
        },
        {
            path: Paths.ForgotPassword,
            component: ForgotPassword,
            isPrivate: false
        },
        {
            path: Paths.Dashboard,
            component: DashBoard,
            isPrivate: true
        },
        {
            path: Paths.ChangePassword,
            component: ChangePassword,
            isPrivate: true
        },
        {
            path: Paths.Administrators,
            component: Administrators,
            isPrivate: true
        },
        {
            path: Paths.Customers,
            component: Customers,
            isPrivate: true
        },
        {
            path: Paths.Users,
            component: Users,
            isPrivate: true
        },
        {
            path: Paths.Courses,
            component: Courses,
            isPrivate: true
        },
        {
            path: Paths.Deployments,
            component: Deployments,
            isPrivate: true
        },
        {
            path: Paths.createDeployment,
            component: AddDeployment,
            isPrivate: true
        },
        {
            path: Paths.updateDeployment,
            component: AddDeployment,
            isPrivate: true
        },
        {
            path: Paths.Experiments,
            component: Experiments,
            isPrivate: true
        },
        {
            path: Paths.AddExperiment,
            component: AddExperiment,
            isPrivate: true
        },
        {
            path: Paths.MyAssessments,
            component: MyAssesssments,
            isPrivate: true
        },
        {
            path: Paths.AddUsers,
            component: AddUsers,
            isPrivate: true
        },
        {
            path: Paths.AddAdministrator,
            component: AddAdministrator,
            isPrivate: true
        },
        {
            path: Paths.UpdateAdministrator,
            component: AddAdministrator,
            isPrivate: true
        },
        {
            path: Paths.AddCourses,
            component: AddCourses,
            isPrivate: true
        },
        {
            path: Paths.UpdateUsers,
            component: UpdateUsers,
            isPrivate: true
        },
        {
            path: Paths.updateCourses,
            component: AddCourses,
            isPrivate: true
        },
        {
            path: Paths.createCustomer,
            component: CreateCustomer,
            isPrivate: true
        },
        {
            path: Paths.Profile,
            component: Profile,
            isPrivate: true
        },
        {
            path: Paths.Liceses,
            component: Licenses,
            isPrivate: true
        },
        {
            path: Paths.Reporting,
            component: Reporting,
            isPrivate: true
        },
        {
            path: Paths.CustomerOnBoarding,
            component: CustomerOnBoarding,
            isPrivate: true
        },
        {
            path: Paths.DigitalReadliness,
            component: DigitalReadliness,
            isPrivate: true
        },
        {
            path: Paths.LearningService,
            component: LearningService,
            isPrivate: true
        },
        {
            path: Paths.Calendar,
            component: Calendar,
            isPrivate: true
        },
        {
            path: UserPath.UserDashboard,
            component: UserDashboard,
            isPrivate: true
        },
        {
            path: UserPath.Assessments,
            component: Assessments,
            isPrivate: true
        },
        {
            path: UserPath.ExperimentAndTesting,
            component: ExperimentAndTesting,
            isPrivate: true
        },
        {
            path: UserPath.CourseCatalogue,
            component: CourseCatalogue,
            isPrivate: true
        },
        {
            path: UserPath.RegisteredCourses,
            component: RegisteredCourses,
            isPrivate: true
        },
        {
            path: UserPath.WorkReadyness,
            component: WorkReadiness,
            isPrivate: true
        },
        {
            path: UserPath.VirtualGlobalApprenticeship,
            component: VirtualGlobalApprenticeship,
            isPrivate: true
        },
        {
            path: Paths.ThemeEditor,
            component: ThemeEditor,
            isPrivate: false
        },
        {
            path: Paths.CourseRequests,
            component: CourseRequests,
            isPrivate: true
        },
        {
            path: '/*',
            component: PageNotFound,
            isPrivate: false
        }
    ];

    const getThemedata = async () => {
        let bURL = window.location.hostname;
        const themeData = localStorage.getItem('themeData')
            ? localStorage.getItem('themeData')
            : undefined;
        if (themeData === undefined) {
            await authService
                .gettheme(bURL)
                .then(response => {
                    setHide(false);
                    if (response.status === false) {
                        localStorage.setItem(
                            'company_logo',
                            response.data.company_logo ? response.data.company_logo : 'null'
                        );
                    } else {
                        localStorage.setItem(
                            'company_logo',
                            response.data.logo ? response.data.logo : 'null'
                        );
                        localStorage.setItem(
                            'themeData',
                            JSON.stringify(response.data.config.colors)
                        );
                    }
                    resetThemeColors(response?.data?.config?.colors || null);
                })
                .catch(function (error) {
                    setHide(false);
                    localStorage.removeItem("company_logo");
                    console.log(error);
                });
        } else {
            var themeDatas = JSON.parse(themeData);
            resetThemeColors(themeDatas);
            if (hide === true) {
                setHide(false);
            }
        }
        const hostName = window.location.hostname;
        if ( !PLATFORM_HOST_NAME.includes(hostName) ) {
            document.title = 'Learning Platform';
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = localStorage.getItem('company_logo') || null;
        }
    };

    useEffect(() => {
        const userRole = localStorage.getItem('role')
            ? localStorage.getItem('role')
            : undefined;
        if (userRole !== 'adminuser') {
            getThemedata();
        } else {
            resetThemeColors(null);
        }
    }, []);

    return (
        <AuthProvider>
            <CourseProvider>
                <Router>
                    <Switch>
                        <Redirect
                            to={{ pathname: Paths.Login }}
                            exact={true}
                            from={"/"}
                        />
                        {routes.map((route) => (
                            <AppRoutes
                                exact={true}
                                key={route.path}
                                path={route.path}
                                component={route.component}
                                isPrivate={route.isPrivate}
                            />
                        ))}
                    </Switch>
                </Router>
            </CourseProvider>
        </AuthProvider>
    )
};

export default Routes;
