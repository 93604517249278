import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Layout from "../../Components/layouts";
import Typography from '@material-ui/core/Typography';
import GenericTable from '../../Components/GenericTable';
import { Paths } from '../../Routes/routePaths'
import { AdministratorServices } from '../../Services/AdministratorServices';
import { Pagination } from '@material-ui/lab';
import OutlinedButton from 'Components/OutlinedButton';
import SearchBoxInput from 'Components/SearchBoxInput';
import { useHistory } from 'react-router';
import commonStyles from 'theme/commonStyles';
import variables from '../../Sys/variable.scss';

const administratorServices = new AdministratorServices();

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: 180,
        background: '#FFFFFF',
        boxShadow: "0px 4px 30px rgba(12, 41, 84, 0.1)",
        borderRadius: 5
    },
    cardDetails: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 174,
        height: 132,
        paddingTop: 17,
        marginTop: 25,
        marginLeft: 20,
        borderRadius: 5
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    cardTitle: {
        color: "#808080"
    },
    cardSubTitle: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        color: "#3D7DDD",
    },
    pageTitle: {
        marginBottom: 32,
        color: variables.labelcolor
    }
}));

const columns = [
    { label: "First Name", key: 'firstName' },
    { label: "Last Name", key: 'lastName' },
    { label: "Username", key: 'userName' },
    { label: "Email", key: "email" },
    { label: "Location", key: "location" },
    { label: "Created Date", key: "created_at" },
    { label: "Action", key: "action", actions: ["edit"] }
];

const Administrators = () => {

    const classes = useStyles();
    const history = useHistory();
    const commonClasses = commonStyles();
    
    const [filterState, setFilterState] = React.useState({ page: 1, search: "" });
    const [administratorList, setAdministratorList] = React.useState([]);
    const [pagination, setPagination] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        getAllAdministrator(filterState);
    }, []);

    const getAllAdministrator = async (queryData) => {
        let query = `search=${queryData.search}&page=${queryData.page}`;
        const administratorResult = await administratorServices.getAllAdministrator(`main/superadminlist?${query}`);
        if (administratorResult && administratorResult.results) {
            setAdministratorList(administratorResult.results);
            setPagination(administratorResult.pagination);
        }
        setLoading(false);
    };

    const handlePageChange = (evt, page) => {
        setFilterState({ ...filterState, page });
        return getAllAdministrator({ ...filterState, page });
    };

    const handleSearch = e => {
        e.preventDefault();
        setLoading(true);
        return getAllAdministrator({ ...filterState, page: 1 });
    };

    const handleItemClick = (row) => {
        if (!row) return null;

        history.push(Paths.AddAdministrator + '/' + row.uuid);
    }

    const handleCreateNew = () => {
        history.push(Paths.AddAdministrator);
    }

    return (
        <Layout>
            <main>
                <div className={commonClasses.contentWrapper}>
                    <Typography variant="h4" className={classes.pageTitle}>Administrators</Typography>
                    <Grid container justifyContent={'space-between'}>
                        <Grid item>
                            <SearchBoxInput 
                                value={filterState.search} 
                                handleChange={(evt) => {
                                    setFilterState({ ...filterState, search: evt.target.value })
                                    return getAllAdministrator({ search: evt.target.value, page: 1 });
                                }}
                                handleSearch={handleSearch} 
                            />
                        </Grid>
                        <Grid item>
                            <OutlinedButton 
                                title={"Create Administrator"} 
                                handleClick={handleCreateNew} 
                                align={'right'} 
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: 20 }}>
                        <GenericTable
                            columns={columns}
                            loading={loading}
                            handleItemClick={handleItemClick}
                            list={administratorList}
                        />
                    </Grid>
                    <Grid container style={{ marginTop: 22 }} >
                        {pagination &&
                            <Pagination
                                onChange={handlePageChange}
                                style={{ margin: "0 auto" }}
                                count={parseInt(Math.ceil(pagination.total_entries / pagination.end_index))}
                                variant="outlined"
                                shape="rounded"
                                siblingCount={0}
                            />}
                    </Grid>
                </div>
            </main>
        </Layout>
    );
}

export default Administrators;