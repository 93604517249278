import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import {
    AppBar,
    Toolbar,
    IconButton,
    Button,
    Avatar,
    Typography,
} from '@material-ui/core';
import { SkillsAndTransformation } from 'Services/SkillsAndTransformation';
import SearchBox from 'Components/SearchBox';
import { NavLink } from 'react-router-dom';
import logoImg from '../../../Assets/images/logo.jpg';
import { logout, useAuthDispatch, useAuthState } from '../../../Context';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import userImg from '../../../Assets/images/loading-waiting.gif';
import defaultImage from '../../../Assets/images/customer-default-logo.png';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Paths, UserPath } from '../../../Routes/routePaths'
import EditIcon from '@material-ui/icons/Edit';
import { UserServices } from '../../../Services/UserServices';
import { useHistory } from 'react-router';
import variables from '../../../Sys/variable.scss';
import { USER_ROLES } from '../../../utils/constants';
import DynamicAutoSearch from 'Components/DynamicAutoSearch';
import { CoursesServices } from 'Services/CoursesServices';
import { useContext } from 'react';
import { CourseContext } from 'Context/CourseProvider';
import { getEncodeURL } from 'utils';

const userServices = new UserServices();
const coursesService = new CoursesServices();
const skillsAndTransformation = new SkillsAndTransformation();

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    headerBar: {
        backgroundColor: variables.headerColor,
        height: 95,
        boxShadow: "none"
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    searchBar: {
        flexGrow: 1,
        marginLeft: 35,
        display: 'inline-flex',
        alignItems: 'center',
        gap: 10,
        marginTop: 20
    },
    iconBtn: {
        borderRadius: "50%"
    },
    profileImg: {
        width: 80,
        height: 80,
        margin: "0 auto",
        overflow: "hidden",
        border: "1px solid",
        borderRadius: "50%",
        backgroundColor: "#262626"
    },
    editBack: {
        position: "absolute",
        width: 75,
        height: 26,
        bottom: 0,
        textAlign: "center",
        paddingTop: 6,
        color: "#fff",
        background: "rgba(255, 255, 255, 0.6)"
    },
    rightMenuItem: {
        display: 'inline-block',
        paddingRight: 35,
        '&:hover': {
            color: '#3C7DDD'
        }
    },
    menuContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto auto',
        backgroundColor: 'red',
    },
    menuContainerItem: {
        margin: 15,
    },
    header: {
        fontSize: '16px',
        fontStyle: 'bold',
        fontWeight: 800,
    },
    menuSubContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    viewAll: {
        color: '#3C7DDD',
        cursor: 'pointer',
    },
    lastRow: {
        gridColumn: 'span 4',
        justifySelf: 'end',
    },
    browseAll: {
        color: '#3C7DDD',
        background: 'white',
        cursor: 'pointer',
        padding: '20px 45px',
        marginTop: 40,
    }
}));

export const TopBarButton = ({
    name,
    icon,
    layout,
    path,
    button = false,
    activeClassName = 'active'
}) => (
    <NavLink
        to={layout + path}
        activeClassName={activeClassName}
        exact
        id="topBar_nav">
        <Button
            color={button ? 'primary' : 'secondary'}
            variant={button ? 'contained' : 'default'}
            size="large"
            id="topBar_button">
            <span id="topBar_span" ><i className={icon} /></span>
            <span> {name} </span>
        </Button>
    </NavLink>
);

const Header = () => {

    const classes = useStyles();
    const history = useHistory();
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [profileLoading, setProfileLoading] = useState(false);
    const anchorRef = React.useRef(null);
    const [logoUrl, setLogoUrl] = useState((localStorage.getItem('company_logo') && localStorage.getItem('company_logo') !== 'null') ? localStorage.getItem('company_logo') : logoImg);
    const [searchCourses, setSearchCourses] = useState([]);
    const [searchOpen, setSearchOpen] = useState(false);
    const [optLoading, setOptLoading] = useState('not');
    const [otherImgWidth, setOtherImgWidth] = useState(0);
    const { courseCatalogues } = useContext(CourseContext);

    const dispatch = useAuthDispatch();
    const { userDetails } = useAuthState(dispatch);

    const menuRef = React.useRef(null);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const menu_items = [
        { name: "Dashboard", path: Paths.Dashboard },
        { name: "In-progress", path: UserPath.RegisteredCourses + '?type=inprogress' },
        { name: "Completed", path: UserPath.RegisteredCourses + '?type=completed' },
    ];
    const [loading, setLoading] = useState(true);
    const [courseCatalogueList, setCourseCatalogueList] = useState([]);
    const [userRole, setUserRole] = useState(USER_ROLES.USER);
    const [globalSearchVal, setGlobalSearchVal] = useState("");

    React.useEffect(() => {
        let courseList = courseCatalogues;
        if (courseList?.status) {
            setLoading(false);
            setCourseCatalogueList(courseList.data);
        };
    }, [courseCatalogues]);

    const handleToggleMenu = () => {
        setMenuOpen((prevOpen) => !prevOpen);
    };

    React.useEffect(() => {
        if (userDetails) {
            getUserProfile();
            setUserRole(userDetails?.role || USER_ROLES.USER);
            if (window.location.hostname !== 'platform.deviare.co.za') {
                setLogoUrl(userDetails.company_logo || logoImg);
                setOtherImgWidth(85);
            }
        }
    }, [userDetails]);

    const getUserProfile = async () => {
        setProfileLoading(true);
        const user = await userServices.getDetailMyProfile();
        if (user && user.data && user.data.length) {
            setLoggedInUser(user.data[0]);
            localStorage.setItem('comp_ids', user.data[0].uuid);
        }
        setProfileLoading(false);
    }

    const handleLogout = async () => {
        await logout(dispatch);
        window.location.href = "/";
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleProfile = () => {
        history.push(Paths.Profile);
    }

    const handleChangePassword = () => {
        history.push(Paths.ChangePassword);
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const handleGlobalSearch = async (value) => {
        setGlobalSearchVal(value);
    };

    React.useEffect(() => {
        if(!globalSearchVal){
            setSearchCourses([]);
        }else{
            const getData = setTimeout(async () => {
                getSearchCourseList(globalSearchVal);
            }, 500);
            return () => clearTimeout(getData);
        }
    }, [globalSearchVal]);

    const getSearchCourseList = async (searchVal) => {
        setOptLoading('');
        const courseList = await coursesService.getEcommerceCourses(searchVal ? `name=${searchVal}` : '');
        if (courseList.status && courseList.data) {
            const courses = [];
            courseList.data.map((itm) => {
                itm.courses.map((cs) => {
                    if (courses.length < 11) {
                        courses.push({ ...cs });
                    }
                });
            });
            setSearchCourses(courses);
        }
        setOptLoading('not');
    }

    const handleSubManuClicked = (itm) => {
        setMenuOpen(false);
        history.push(itm ? getEncodeURL(`${UserPath.CourseCatalogue}?category=${itm.category}`) : UserPath.CourseCatalogue);
    }

    return (
        <div className={classes.root}>
            <AppBar
                position="static"
                className={classes.headerBar}
            >
                <Toolbar>
                    {userRole === USER_ROLES.USER ? (
                        <>
                            <div className={classes.title}>
                                <img
                                    style={{ cursor: "pointer", paddingTop: 8, width: otherImgWidth ? otherImgWidth : 150 }}
                                    alt="Deviare"
                                    onClick={() => history.push(Paths.Dashboard)}
                                    src={logoUrl}
                                />
                            </div>
                            <div className={classes.searchBar}>
                                <DynamicAutoSearch
                                    open={searchOpen}
                                    handleChange={(evt, newVal) => {
                                        window.location.href = `/skill-transformation/course-catalogue?category=${newVal.category}`;
                                    }}
                                    handleOpen={(val) => {
                                        setSearchOpen(val);
                                    }}
                                    options={searchCourses}
                                    handleSearch={handleGlobalSearch}
                                    optLoading={optLoading}
                                />
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    disabled={loading}
                                    ref={menuRef}
                                    aria-controls={menuOpen ? 'menu-list-grow' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggleMenu}
                                    style={{ padding: '10px 45px', borderRadius: 10 }}>
                                    Explore {menuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </Button>
                            </div>
                        </>
                    ) : (
                        <div className={classes.title}>
                            <img
                                style={{ cursor: "pointer", paddingTop: 8, width: (otherImgWidth) ? otherImgWidth : 150 }}
                                alt="Deviare"
                                onClick={() => history.push(Paths.Dashboard)}
                                src={logoUrl}
                            />
                        </div>
                    )
                    }

                    <div>
                        {userRole === USER_ROLES.USER && (
                            <div className={classes.rightMenuItem}>

                                {menu_items.map((menuItem) => (
                                    <Button
                                        style={{ color: variables.darkThemeText }}
                                        onClick={() => history.push(menuItem.path)}
                                    >
                                        {menuItem.name}
                                    </Button>
                                ))}
                            </div>
                        )}
                        <IconButton className={classes.iconBtn} style={{ paddingRight: 35 }} aria-label="show 17 new notifications">
                            <Badge color="secondary">
                                <NotificationsIcon style={{ color: variables.darkThemeText }} />
                            </Badge>
                        </IconButton>
                        <IconButton className={classes.iconBtn} aria-label="show 17 new notifications">
                            <Avatar alt="Deviare" src={profileLoading ? userImg : loggedInUser && loggedInUser.profile_image ? loggedInUser.profile_image : defaultImage} className={classes.largeImg} />
                        </IconButton>
                        <Button
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            style={{ color: variables.darkThemeText }}
                            onClick={handleToggle}
                        >
                            {(loggedInUser) ? `${loggedInUser.firstName} ${loggedInUser.lastName}` : "Profile"} <ExpandMoreIcon />
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>
            <Popper open={open} anchorEl={anchorRef.current} transition disablePortal style={{ zIndex: 999 }}>
                {({ TransitionProps, placement }) => (
                    <Grow style={{ width: "197px", backgroundColor: variables.headerColor, marginTop: 34, transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }} {...TransitionProps} >
                        <Paper>
                            <div style={{ width: "100%", padding: 10, borderBottom: "1px solid #DEDEDE" }}>
                                <div style={{ position: "relative" }} className={classes.profileImg}>
                                    <img alt='user logo' width={'80px'} height={'80px'} src={profileLoading ? userImg : loggedInUser && loggedInUser.profile_image ? loggedInUser.profile_image : defaultImage} className={classes.largeImg} />
                                    <span className={classes.editBack}><EditIcon style={{ fontSize: 16, fontWeight: "bold", cursor: "pointer" }} /></span>
                                </div>
                            </div>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    <MenuItem style={{ color: variables.darkThemeText }} onClick={handleProfile}>Edit Profile</MenuItem>
                                    <MenuItem style={{ color: variables.darkThemeText }} onClick={handleChangePassword}>Change Password</MenuItem>
                                    <MenuItem style={{ color: variables.darkThemeText }} onClick={handleLogout}>Logout</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

            {userRole === USER_ROLES.USER && (
                <Popper open={menuOpen} anchorEl={menuRef.current} transition disablePortal style={{ zIndex: 2, width: '100%' }}>
                    {({ TransitionProps, placement }) => (
                        <Grow style={{ width: "99%", backgroundColor: variables.headerColor, marginTop: 25, padding: 35 }} {...TransitionProps} >
                            <div className={classes.menuContainer}>
                                {
                                    courseCatalogueList.slice(0, 8).map((courseItem) => (
                                        <div className={classes.menuContainerItem}>
                                            <Typography variant="h3" className={classes.header}>
                                                {courseItem.category}
                                            </Typography>
                                            <div className={classes.menuSubContainer}>
                                                {courseItem.courses.slice(0, 4).map((item) => (
                                                    <Button
                                                        style={{ color: variables.darkThemeText, cursor: 'pointer' }}
                                                        onClick={() => handleSubManuClicked(item)}
                                                    >
                                                        {item.name.length <= 35 ? item.name : item.name.substring(0, 35).concat('...')}
                                                    </Button>
                                                ))}
                                                {
                                                    courseItem.courses.length > 4 && (
                                                        <Button
                                                            className={classes.viewAll}
                                                            onClick={() => handleSubManuClicked(courseItem)}
                                                        >
                                                            View All
                                                        </Button>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    ))
                                }

                                <div className={classes.lastRow}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        style={{ padding: '10px 45px', borderRadius: 10 }}
                                        className={classes.browseAll}
                                        onClick={() => handleSubManuClicked()}
                                    >
                                        Browse All Courses
                                    </Button>
                                </div>

                            </div>
                        </Grow>
                    )}
                </Popper>
            )
            }
        </div>
    );
};

export default Header;