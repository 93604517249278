export const ERROR_MESSAGE = {
    REQUIRED_LOGIN: "Please enter your Username and Password",
    VALID_EMAIL: "Please enter your valid email address",
    VALID_PASSWORD: "Password must be more then 5 digit",
    INVALID_CRED: "Invalid login credentials!",
    SELECT_COMPANY: "Please select company!"
}
export const REQUIRED_ERROR = "Fields marked as * are required.";
export const SUCCESS_MESSAGE = {
    LOGIN_SUCCESS: "Logged in successfully"
}
export const USER_ROLES = {
    SUPER_ADMIN: "superadmin",
    USER: "user",
    CUSTOMER_ADMIN: "customeradmin",
    PROJECT_ADMIN: "projectadmin",
    CSM_ADMIN: 'csmadmin'
}

export const analyticsColumns = [
    {
        label: 'Username',
        csvKey: 'Username',
        key: 'Username',
        sortType: 'basic'
    },
    {
        label: 'Learner Email',
        csvKey: 'email',
        key: 'Learner Email',
        sortType: 'basic'
    },
    {
        label: 'Order Type',
        csvKey: 'order_type',
        key: 'order_type',
        sortType: 'basic'
    },
    {
        label: 'Activity Level',
        csvKey: 'activity_level',
        key: 'activity_level',
        sortType: 'basic'
    },
    {
        label: 'Cohort',
        csvKey: 'Cohort',
        key: 'Cohort',
        sortType: 'basic'
    },
    {
        label: 'Course Id',
        csvKey: 'course_id',
        key: 'course_id',
        sortType: 'basic'
    },
    {
        label: 'Course Name',
        csvKey: 'program',
        key: 'course_title',
        sortType: 'basic'
    },
    {
        label: 'Course Assignment Date',
        csvKey: 'course_assignment_date',
        key: 'course_assignment_date',
        sortType: 'basic'
    },
    {
        label: 'Course Completion Date',
        csvKey: 'course_completion_date',
        key: 'course_completion_date',
        sortType: 'basic'
    },
    {
        label: 'Course Activation Date',
        csvKey: 'course_activation_date',
        key: 'course_activation_date',
        sortType: 'basic'
    },
    {
        label: 'Course Expiration Date',
        csvKey: 'course_expiration_date',
        key: 'course_expiration_date',
        sortType: 'basic'
    },
    {
        label: 'Course Type',
        csvKey: 'course_type',
        key: 'course_type',
        sortType: 'basic'
    },
    {
        label: 'Self-Learning Completion',
        csvKey: 'self_learning_completion',
        key: 'self_learning_completion',
        sortType: 'basic'
    },
    {
        label: 'Assessment Test',
        csvKey: 'Assessment Test',
        key: 'Assessment Test',
        sortType: 'basic'
    },
    {
        label: 'Project Status',
        csvKey: 'project_result',
        key: 'Project Status',
        sortType: 'basic'
    },
    {
        label: 'Live Class Attended',
        csvKey: 'live_class_attended',
        key: 'live_class_attended',
        sortType: 'basic'
    },
    {
        label: 'OSL Score',
        csvKey: 'osl_score',
        key: 'osl_score',
        sortType: 'basic'
    },
    {
        label: 'LVC Score',
        csvKey: 'lvc_sore',
        key: 'lvc_sore',
        sortType: 'basic'
    },
    {
        label: 'Certification Status',
        csvKey: 'certification_status',
        key: 'certification_status',
        sortType: 'basic'
    },
    {
        label: 'Last Login Date',
        csvKey: 'last_login_date',
        key: 'last_login_date',
        sortType: 'basic'
    },
    {
        label: 'Last Activity on',
        csvKey: 'last_activity_on',
        key: 'last_activity_on',
        sortType: 'basic'
    },
    {
        label: 'Self Learning Time',
        csvKey: 'self_learning_time',
        key: 'self_learning_time',
        sortType: 'basic'
    },
    {
        label: 'Overall course completion score',
        csvKey: 'Assessment Test',
        key: 'test_score',
        sortType: 'basic'
    }
]
export const overViewColumn = [
    {
        label: 'Username',
        key: 'Username',
        csvKey: 'Username',
        sortType: 'basic'
    },
    {
        label: 'Course Name',
        key: 'course_title',
        csvKey: 'course',
        sortType: 'basic'
    },
    {
        label: 'Activity Level',
        key: 'activity_level',
        csvKey: 'activity_level',
        sortType: 'basic'
    },
    {
        label: 'Certification Status',
        key: 'certification_status',
        csvKey: 'certification_status',
        sortType: 'basic'
    },
    {
        label: 'Course Assignment Date',
        key: 'assign_date',
        csvKey: 'course_assignment_date',
        sortType: 'basic'
    },
    {
        label: 'Course Completion Date',
        key: 'completion_date',
        csvKey: 'course_completion_date',
        sortType: 'basic'
    }
]

export const progressColumn = [
    {
        label: 'Username',
        key: 'Username',
        csvKey: 'Username',
        sortType: 'basic'
    },
    {
        label: 'Course Name',
        key: 'course',
        csvKey: 'course',
        sortType: 'basic'
    },
    {
        label: 'Activity Level',
        key: 'activity_level',
        csvKey: 'activity_level',
        sortType: 'basic'
    },
    {
        label: 'Assessment Test',
        key: 'Assessment Test',
        csvKey: 'test_score',
        sortType: 'basic'
    },
    {
        label: 'Self-Learning Completion',
        key: 'self_learning',
        csvKey: 'self_learning_completion',
        sortType: 'basic'
    },
    {
        label: 'Live Class Attended',
        key: 'live_class',
        csvKey: 'live_class_attended',
        sortType: 'basic'
    },
    {
        label: 'Project Status',
        key: 'Project Status',
        csvKey: 'project_result',
        sortType: 'basic'
    },
    {
        label: 'Overall course completion score',
        key: 'overall_course_completion_score',
        csvKey: 'overall_course_completion_score',
        sortType: 'basic'
    },
    {
        label: 'Certification Status',
        key: 'certification_status',
        csvKey: 'certification_status',
        sortType: 'basic'
    }
];

export const RespondentColumn = [
    {
        label: 'Customer',
        key: 'customer_name',
        sortType: 'basic'
    },
    {
        label: 'Project',
        key: 'project_name',
        sortType: 'basic'
    },
    {
        label: 'Name',
        key: 'name',
        sortType: 'basic'
    },
    {
        label: 'Surname',
        key: 'surname',
        sortType: 'basic'
    },
    {
        label: 'Username',
        key: 'email',
        sortType: 'basic'
    },
    {
        label: 'Customer Dimension',
        key: 'customer_dim',
        sortType: 'basic'
    },
    {
        label: 'Strategy Dimension',
        key: 'strategy_dim',
        sortType: 'basic'
    },
    {
        label: 'Technology Dimension',
        key: 'technology_dim',
        sortType: 'basic'
    },
    {
        label: 'Operations Dimension',
        key: 'operations_dim',
        sortType: 'basic'
    },
    {
        label: 'Culture Dimension',
        key: 'culture_dim',
        sortType: 'basic'
    },
    {
        label: 'Data Dimension',
        key: 'data_dim',
        sortType: 'basic'
    }
]

export const analyticsArr = [
    'Select Customer',
    'Select Certificate',
    'Select Project',
    'Select Courses'
]

export const overviewArr = [
    'Select Customer',
    'Select Certificate',
    'Select Team',
    'Select Courses'
]

export const progressArr = [
    'Select Customer',
    'Select Certificate',
    'Select Project',
    'Select Courses'
]

export const CustomerDimentionDesc = `The Customer dimension evaluates the provision of an engaging 
experience where customers view the organization as their digital partner using their preferred 
channels of interaction.`

export const StrategyDimentionDesc = `The Strategy Dimension evaluates how well the business plans to 
increase its competitive advantage through a comprehensive digital strategy and designs a set of 
initiatives that support the overall business strategy.`

export const TechnologyDimentionDesc = `The Technology dimension evaluates the technology capabilities
 of the organization to establish, maintain and continually transform an environment that supports the
 delivery of business objectives.`

export const OperationDimentionDesc = `The Operations dimension evaluates the organization's performance of 
day-to-day activities that support the execution of the digital strategy.`

export const CultureDimentionDesc = `The Culture dimension evaluates the ability of an organization to create
an environment where everyone is willing and able to create business value.`

export const DataDimentionDesc = `The Data dimension evaluates the organization's ability both strategically 
and operationally to ethically and effectively use data and information assets to maximize business
value.`

export const ClosingDesc = `The Gap is difference between your company's status quo aspiration 
responses from the digital readiness assessment. Engage our experts to leverage the Digital Readiness 
Assessment and advance your organization's digital transformation journey. 
Contact connect@deviare.africa.`

export const customerFactor = [
    'Customer outside-in view',
    'Customer experience management',
    'Customer insights',
    'Customer trust'
]

export const strategyFactor = [
    'Marketing & brand management',
    'Ecosystem management',
    'Financial sponsorship',
    'Market intelligence',
    'Portfolio management',
    'Strategy management'
]

export const technologyFactor = [
    'Technology governance',
    'Technology & application architecture',
    'Security',
    'Applications & platforms',
    'Connectivity & compute'
]

export const operationFactor = [
    'Operations governance',
    'Service design & innovation',
    'Service transition/deployment',
    'Service operations'
]

export const cultureFactor = [
    'Organisational values',
    'Talent management',
    'Workplace enablement'
]

export const dataFactor = [
    'Data governance',
    'Data engineering',
    'Data value realisation'
]

export const AssesmentsSummary = `Digital transformation begins with understanding your organisation’s capacity and capability for digital advancement. These set of assessments lay the foundation for your company’s digital transformation journey.`

export const DurationList = [
    '15 Minutes',
    '30 Minutes',
    '45 Minutes',
    '1 Hour'
]

export const RepeatMode = [
    "Daily",
    "Weekly",
    "Monthly"
]

export const DaysList = [
    'Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'
] 

export const PLATFORM_HOST_NAME = ['platform.deviare.co.za', 'platform-staging.deviare.co.za']