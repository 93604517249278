import * as React from 'react';
import Layout from "../../../../Components/layouts";
import aiImg from '../../../../Assets/images/ai.png';
import { CircularProgress, Grid } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import { Pagination } from '@material-ui/lab';
import { SkillsAndTransformation } from 'Services/SkillsAndTransformation';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { useLocation } from "react-router-dom";
import { Paths } from 'Routes/routePaths';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { checkCurrentEventTime } from 'utils';

const skillsAndTransformation = new SkillsAndTransformation();

export const calculatePercentage = (val, total) => {
    let percentage = parseFloat((val / total)) * 100;
    return percentage ? Math.floor(percentage) : 0;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: 180,
        background: '#FFFFFF',
        boxShadow: "0px 4px 30px rgba(12, 41, 84, 0.1)",
        borderRadius: 5
    },
    content: {
        flex: '1 0 auto',
        maxWidth: '80%',
    },
    cover: {
        width: 174,
        height: 132,
        paddingTop: 17,
        marginTop: 25,
        marginLeft: 20,
        borderRadius: 5,
        maxWidth: '20%'
    },
    cardTitle: {
        color: "#808080"
    },
    cardDesc: {
        color: "#808080",
    },
}));

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  

const WorkReadiness = () => {

    const classes = useStyles();
    const [workReadynessList, setWorkReadynessList] = React.useState([]);
    const [loader, setLoader] = React.useState(false);
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get("type") || null;
    const [open, setOpen] = React.useState(false);


    React.useEffect(() => {
        setWorkReadynessList([]);
        setLoader(true);
        getAllRegisterCourse();
    }, [location]);

    const getAllRegisterCourse = async () => {
        const courseList = await skillsAndTransformation.getRegisterCourse("Work readiness");
        if (courseList.status) {
            const courseArr = [];
            courseList?.data?.courses.forEach((cat) => {
                if(cat.category === 'Work readiness'){
                    cat.data.forEach((course) => {
                        let itm = course?.course;
                        const startDateTime = itm?.event_start_date + ' ' + itm.event_start_time;
                        const endDateTime = itm?.event_end_date + ' ' + itm?.event_end_time;
                        const checkLaunchTime = (startDateTime && endDateTime) ? !checkCurrentEventTime(new Date(startDateTime), new Date(endDateTime)) : true;
                        
                        let obj = {
                            title: 'Work Readiness',
                            subTitle: itm?.course_name || '',
                            desc: itm?.description,
                            image: aiImg,
                            total: 100,
                            value: itm?.course_completion,
                            width: 12,
                            page: 'registeredCourse',
                            progress: true,
                            provider: itm?.provider,
                            checkLaunchTime: checkLaunchTime,
                            ...itm
                        }

                        if(type === "inprogress" && parseFloat(course.course_completion) < 100 && parseFloat(course.course_completion) > 0){
                            courseArr.push(obj);
                        }else if(type === "completed" && parseFloat(course.course_completion) >= 100){
                            courseArr.push(obj);
                        }else if(type === null){
                            courseArr.push(obj);
                        }
                    });
                }
            });
            setWorkReadynessList(courseArr);
        }
        setLoader(false);
    }

    const handleLinkURL = (itm) => {
        const { event_url, checkLaunchTime } = itm;
        if(!checkLaunchTime){
            window.open(event_url);
        }else{
            setOpen(true);
        }
    };

    return (
        <Layout>
            <main>
                <Grid container spacing={2}>
                    {loader && <div style={{ marginTop: 30, textAlign: "center", width: "100%" }}>
                        <CircularProgress />
                    </div>}
                    {workReadynessList?.length ? workReadynessList.map((item) => (
                        <>
                            <Grid key={item.title} item xs={item.width}>
                                <Card className={classes.root} sx={{ display: "flex" }}>
                                    <CardMedia
                                        className={classes.cover}
                                        image={item.image}
                                        title={item.title}
                                    />
                                    <CardContent className={classes.content} style={{ display: 'flex' }}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={9} style={{height: 180, overflow: 'auto'}}>
                                                <Typography className={classes.cardTitle}>
                                                    {item?.title || ''}
                                                </Typography>
                                                <Typography variant="h1" color="primary">
                                                    {item?.subTitle || ''}
                                                </Typography>
                                                <Typography className={classes.cardTitle}>
                                                    {item?.event_start_date || ''} {item?.event_start_time || ''}
                                                </Typography>
                                                <Typography className={classes.cardDesc} title={item.desc}>
                                                    {item?.desc || ''}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} style={{ margin: 'auto' }}>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => handleLinkURL(item)}
                                                    style={{ width: '100%', padding: '15px 45px', borderRadius: 10 }}>
                                                    {parseFloat(item.value) > 0 ? (
                                                        "Continue learning"
                                                    ) : (
                                                        "LAUNCH"
                                                    )}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </>
                    )) : (!loader) ? 
                    <Grid item xs={12} style={{textAlign: "center"}}>
                        <Typography variant='h4' >No Course Registered!</Typography>
                    </Grid> : null}
                </Grid>
                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            There is no event at the moment, please check the Calendar for upcoming events.
                            <Button onClick={() => history.push(Paths.Calendar)} color="primary" autoFocus>
                                Go to Calendar
                            </Button> 
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </main>
        </Layout>
    );
}

export default WorkReadiness;