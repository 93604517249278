import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import { useParams } from "react-router-dom";
import PhoneInputField from '../../../../Components/PhoneInputField';
import { TextField, Button, CircularProgress } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import OutlinedSelect from '../../../../Components/OutlinedSelect';
import { ERROR_MESSAGE, REQUIRED_ERROR } from '../../../../utils/constants';
import { checkValidEmail, notify } from '../../../../utils';
import { timezone } from '../../../../Assets/timeZone';
import { accountList, useStyles, initCustomerFormState } from '../../../SuperAdmin/Customers/Create';
import { useStyle as onboardingStyle } from '../../../CustomerOnBoarding';
import { CustomerServices } from '../../../../Services/CustomerServices';
import { useParams } from 'react-router';
import SendTestEmailModal from '../../../../Components/SendTestEmailModal';
import variables from '../../../../Sys/variable.scss';

const customerServices = new CustomerServices();
const initFormState = { ...initCustomerFormState, email_host: '', port: '22', from: '', email_host_user: '', email_host_password: '', email_subject_prifix: '', recipients: '', secure_connection: false, use_authentication: false };

const Customer = ({
    activeStep,
    countryList,
    handleBack,
    handleNext,
    formBackupState,
    handleUpdateBackupFormData
}) => {

    const classes = useStyles();
    const onBorningClasses = onboardingStyle();
    const { id } = useParams();
    const [showSMTP, setShowSMTP] = React.useState(false);
    const [submitLoading, setSubmitLoading] = React.useState(false);
    const [formState, setFormState] = React.useState({ ...initFormState });
    const [sendTestMailOpen, setSendTestMailOpen] = React.useState(false);
    const [sendTestEmailLoading, setSendTestEmailLoading] = React.useState(false);

    React.useEffect(() => {
        if (formBackupState) {
            setFormState({ ...formBackupState });
        }
        if (id) {
            getCustomerDetail();
        }
    }, [formBackupState]);

    async function getCustomerDetail() {
        const csDetails = await customerServices.getCustomerDetail(id);
        if (csDetails && csDetails.data && csDetails.data.length) {
            let reponseData = csDetails.data[0];
            let domain = reponseData.domain;
            domain = domain ? domain.split('.')[0] : '';
            setFormState({
                email: reponseData.email,
                contact_no: reponseData.contact_no
                    ? `+${reponseData.contact_no.toString()}`
                    : '',
                name: reponseData.name,
                address: reponseData.address,
                country: reponseData.country,
                domain: domain,
                partner: reponseData.partner,
                branch: reponseData.branch || '',
                email_host: reponseData.email_host,
                email_host_password: reponseData.email_host_password,
                email_host_user: reponseData.email_host_user,
                email_subject_prifix: reponseData.email_subject_prifix,
                from: reponseData.from,
                logo: reponseData.logo,
                port: '22',
            });
            setShowSMTP(reponseData.email_host ? true : false);
            localStorage.setItem('cstmsrSaveFlow', id);
            localStorage.setItem('customerUUID', id);
        }
    }

    const handleInputChange = (e, field) => {
        if (field) {
            setFormState({ ...formState, [field]: e });
        } else {
            const { name, value } = e.target;
            setFormState({ ...formState, [name]: value });
        }
    }

    const handleChangeSMTP = (evt) => {
        setShowSMTP(evt.target.checked);
    }

    const formValidationCheck = (evt) => {
        if (!formState.name || !formState.email || !formState.address || !formState.country || !formState.partner || !formState.logo) {
            notify("error", REQUIRED_ERROR);
            return null;
        }
        if (!checkValidEmail(formState.email)) {
            notify("error", ERROR_MESSAGE.VALID_EMAIL);
            return null;
        }
        return handleFormSubmit(evt);
    }

    const handleFormSubmit = async (evt) => {
        setSubmitLoading(true);
        let payload = { ...formState };
        let editstep = localStorage.getItem('editstep');
        let customer_id = null;
        if (editstep === '0' || id) {
            customer_id = localStorage.getItem('customerUUID') || id;
            if (payload.domain) {
                payload.domain = (payload.partner === 'reseller_partner') ? formState.domain + '.partner.deviare.co.za' : formState.domain + '.platform.digitalskillsonline.net';
            } else {
                payload.domain = '';
            }
        } else {
            payload.domain = (payload.partner === 'reseller_partner') ? formState.domain + '.partner.deviare.co.za' : formState.domain + '.platform.digitalskillsonline.net';
        }
        payload.timezone = '';
        payload.link = '';
        payload.api_key = '';
        try {
            const result = await customerServices.addCustomer(payload, customer_id);
            if (result && result.status) {
                localStorage.setItem('cstmsrSaveFlow', result.data.uuid);
                localStorage.setItem('customerUUID', result.data.uuid);
                localStorage.setItem('getAllCustomer', true);
                handleNext(evt);
                handleUpdateBackupFormData({ ...formState }, 'customer');
                notify("success", result.message);
            } else {
                setSubmitLoading(false);
                notify("error", (result && result.message) ? result.message : 'Something Went Wrong');
            }
        } catch (e) {
            setSubmitLoading(false);
            notify("error", (e && e.message) ? e.message : 'Something Went Wrong');
        }
    }

    const handleUploadImage = (e) => {
        let file = e.target.files[0];
        if (!file) return;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            handleInputChange(reader.result, 'logo');
        };
    };

    const handleChangeCheckItem = (name) => {
        handleInputChange(!formState[name], name);
    }

    const handleSendEmailSubmit = async (recipients) => {
        setSendTestEmailLoading(true);
        let sendEmailData = { ...formState };
        let data = {
            email_host: sendEmailData.email_host,
            email_subject_prifix: sendEmailData.email_subject_prifix,
            email_host_user: sendEmailData.email_host_user,
            email_host_password: sendEmailData.email_host_password,
            recipients: recipients
        };
        const emailRes = await customerServices.sendTestEmail(data);
        if (emailRes && emailRes.status) {
            notify('success', emailRes?.message || "Email send successfully");
        } else {
            notify("error", (emailRes && emailRes.message) ? emailRes.message : 'Something Went Wrong');
        }
        setSendTestMailOpen(false);
        setSendTestEmailLoading(false);
    }

    return (
        <>
            <Grid container spacing={8}>
                <Grid item xs={4}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                        Company Name *
                    </Typography>
                    <Input disableUnderline value={formState.name}
                        type={'text'}
                        name="name"
                        className={classes.inputStyle}
                        placeholder={"Company Name"}
                        style={{ width: '100%' }}
                        color={'secondary'}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                        Email *
                    </Typography>
                    <Input disableUnderline value={formState.email}
                        type={'text'}
                        className={classes.inputStyle}
                        placeholder={"Email"}
                        disabled={!!id}
                        style={{ width: '100%' }}
                        color={'secondary'}
                        name="email"
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                        Address *
                    </Typography>
                    <Input disableUnderline value={formState.address}
                        type={'text'}
                        className={classes.inputStyle}
                        placeholder={"Address"}
                        style={{ width: '100%' }}
                        name="address"
                        color={'secondary'}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={8}>
                <Grid item xs={6}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                        Country of Registration
                    </Typography>
                    <Autocomplete
                        id="combo-box-demo4"
                        value={formState.country}
                        options={countryList ? countryList : []}
                        getOptionLabel={option => option}
                        onChange={(event, newValue) => handleInputChange(newValue, 'country')}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                color="secondary"
                                placeholder="Select Country"
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                        Account Type *
                    </Typography>
                    <OutlinedSelect
                        val={formState.partner}
                        name="partner"
                        placeholder='Accout Type'
                        variant="standard"
                        handleChange={(evt) => handleInputChange(evt)}
                        styleOverrides={{ width: '100%', marginTop: 0 }}
                        options={accountList}
                    />
                </Grid>
            </Grid>
            {formState.partner && formState.partner !== 'enterprise_customer' &&
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Typography
                            variant="subtitle2"
                            color="textPrimary"
                            style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                            Branch on LMS *
                        </Typography>
                        <Input disableUnderline value={formState.branch}
                            type={'text'}
                            name="branch"
                            className={classes.inputStyle}
                            placeholder={"Branch on LMS"}
                            style={{ width: '100%' }}
                            color={'secondary'}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography
                            variant="subtitle2"
                            color="textPrimary"
                            style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                            Link *
                        </Typography>
                        <Input disableUnderline value={formState.link}
                            type={'text'}
                            name="link"
                            className={classes.inputStyle}
                            placeholder={"Link"}
                            style={{ width: '100%' }}
                            color={'secondary'}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography
                            variant="subtitle2"
                            color="textPrimary"
                            style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                            API Key *
                        </Typography>
                        <Input disableUnderline value={formState.api_key}
                            type={'text'}
                            name="api_key"
                            className={classes.inputStyle}
                            placeholder={"API Key"}
                            style={{ width: '100%' }}
                            color={'secondary'}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography
                            variant="subtitle2"
                            color="textPrimary"
                            style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                            TimeZone
                        </Typography>
                        {/* <OutlinedSelect
                            val={formState.timezone}
                            name="timezone"
                            placeholder='TimeZone'
                            variant="standard"
                            menuProps={{ style: { height: 450 } }}
                            handleChange={(evt) => handleInputChange(evt)}
                            styleOverrides={{ width: '100%', marginTop: 0 }}
                            options={timezone}
                        /> */}
                        <Autocomplete
                            id="tags-outlined"
                            value={formState.timezone}
                            name="timezone"
                            options={timezone}
                            getOptionLabel={option => option.name}
                            onChange={(event, newValue) => {
                                setFormState({ ...formState, timezone: newValue });
                            }}
                            style={{ width: '100%', marginTop: 0 }}
                            limitTags={2}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Select TimeZone"
                                    InputProps={{
                                        ...params.InputProps,
                                        disableUnderline: true,
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            }
            <Grid container spacing={8}>
                <Grid item xs={4}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                        Phone *
                    </Typography>
                    <PhoneInputField
                        handleChange={(newValue) => handleInputChange(newValue, 'contact_no')}
                        value={formState.contact_no}
                    />
                </Grid>
                {formState.partner && formState.partner !== 'enterprise_customer' &&
                    <Grid item xs={4}>
                        <Typography
                            variant="subtitle2"
                            color="textPrimary"
                            style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                            Domain *
                        </Typography>
                        <Input
                            disableUnderline
                            value={formState.domain}
                            type={'text'}
                            name="domain"
                            className={classes.inputStyle}
                            placeholder={"Domain"}
                            style={{ width: '100%' }}
                            color={'secondary'}
                            onChange={handleInputChange}
                        />
                        {formState.partner === 'strategy_partner' &&
                            <div style={{marginTop: 5, width: '100%', textAlign: 'right'}}>
                                <label>
                                    .platform.digitalskillsonline.net
                                </label>
                            </div>
                        }
                        {formState.partner === 'reseller_partner' &&
                            <div style={{marginTop: 5, width: '100%', textAlign: 'right'}}>
                                <label>
                                    .partner.deviare.co.za
                                </label>
                            </div>
                        }
                    </Grid>
                }
                <Grid item xs={4}>
                    <Typography
                        variant="subtitle2"
                        color="textPrimary"
                        style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                        Upload Logo *
                    </Typography>
                    <Input 
                        disableUnderline 
                        className={classes.inputStyle}
                        color={'secondary'}
                        id="file-input"
                        type="file"
                        name="BulkTemplate"
                        onChange={handleUploadImage}
                        inputProps={{ accept: "image/jpeg,image/jpg,image/png" }}
                        style={{ width: '100%' }}
                    />
                </Grid>
            </Grid>
            {formState.partner === 'strategy_partner' &&
                <React.Fragment>
                    <Grid container spacing={1} style={{ marginTop: 20 }}>
                        <Grid item xs={2}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox
                                        onChange={handleChangeSMTP}
                                        checked={showSMTP}
                                        name="smtp" />
                                    }
                                    label="SMTP Settings"
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    {showSMTP && (
                        <React.Fragment>
                            <Grid container spacing={8}>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle2"
                                        color="textPrimary"
                                        style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                        SMTP Server
                                    </Typography>
                                    <Input disableUnderline value={formState.email_host}
                                        type={'text'}
                                        className={classes.inputStyle}
                                        placeholder={"SMTP Server"}
                                        style={{ width: '100%' }}
                                        name="email_host"
                                        color={'secondary'}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle2"
                                        color="textPrimary"
                                        style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                        Port
                                    </Typography>
                                    <Input
                                        disableUnderline
                                        value={formState.port}
                                        type={'text'}
                                        className={classes.inputStyle}
                                        placeholder={"Port"}
                                        style={{ width: '100%' }}
                                        name="port"
                                        color={'secondary'}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={8}>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle2"
                                        color="textPrimary"
                                        style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                        Email Account
                                    </Typography>
                                    <Input
                                        disableUnderline
                                        value={formState.from}
                                        type={'text'}
                                        className={classes.inputStyle}
                                        placeholder={"Email Account"}
                                        style={{ width: '100%' }}
                                        name="from"
                                        color={'secondary'}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle2"
                                        color="textPrimary"
                                        style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                        Mail Subject
                                    </Typography>
                                    <Input
                                        disableUnderline
                                        value={formState.email_subject_prifix}
                                        type={'text'}
                                        className={classes.inputStyle}
                                        placeholder={"Mail Subject"}
                                        style={{ width: '100%' }}
                                        name="email_subject_prifix"
                                        color={'secondary'}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <FormGroup style={{ margin: "10px 0" }}>
                                        <FormControlLabel
                                            control={<Checkbox checked={formState.secure_connection ? true : false} onChange={() => handleChangeCheckItem('secure_connection')} name="secure_connection" />}
                                            label="Use Secure Connection"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={formState.use_authentication ? true : false} onChange={() => handleChangeCheckItem('use_authentication')} name="use_authentication" />}
                                            label="Use Authentication"
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <Grid container spacing={8}>
                                <Grid item xs={4}>
                                    <Typography
                                        variant="subtitle2"
                                        color="textPrimary"
                                        style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                        Login
                                    </Typography>
                                    <Input
                                        disableUnderline
                                        value={formState.email_host_user}
                                        type={'text'}
                                        className={classes.inputStyle}
                                        placeholder={"Login"}
                                        style={{ width: '100%' }}
                                        name="email_host_user"
                                        color={'secondary'}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography
                                        variant="subtitle2"
                                        color="textPrimary"
                                        style={{ marginBottom: '20px', marginTop: '15px', color: variables.labelcolor }}>
                                        Password
                                    </Typography>
                                    <Input disableUnderline value={formState.email_host_password}
                                        type={'password'}
                                        className={classes.inputStyle}
                                        placeholder={"Password"}
                                        style={{ width: '100%' }}
                                        name="email_host_password"
                                        color={'secondary'}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={5}>
                                <Grid item xs={4}>
                                    <Button
                                        type={"button"}
                                        onClick={evt => setSendTestMailOpen(true)}
                                        variant="contained"
                                        color="primary"
                                        style={{ borderRadius: '5px' }}
                                    >
                                        Send Test Email
                                    </Button>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )}
                </React.Fragment>
            }
            {formState.logo &&
                <Grid container spacing={8}>
                    <Grid item xs={3}>
                        <img src={formState.logo} width={120} />
                    </Grid>
                </Grid>
            }
            <SendTestEmailModal
                open={sendTestMailOpen}
                handleClose={evt => setSendTestMailOpen(false)}
                handleSubmit={handleSendEmailSubmit}
                loading={sendTestEmailLoading}
            />
            <div className={onBorningClasses.buttons}>
                <div className={onBorningClasses.wrapper}>
                    {activeStep !== 0 && (
                        <Button
                            onClick={handleBack}
                            variant="contained"
                            color="primary"
                            className={onBorningClasses.cancelBtn}>
                            Back
                        </Button>
                    )}
                    <Button
                        type={"button"}
                        onClick={formValidationCheck}
                        variant="contained"
                        color="primary"
                        disabled={submitLoading}
                        className={onBorningClasses.button}
                    >
                        {submitLoading ? (
                            <CircularProgress
                                size={18}
                                className={classes.buttonProgress}
                            />
                        ) : 'Next'}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default Customer;
