import './App.css';
import React from 'react';
import Routes from './Routes';

import { muiTheme } from './theme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import './Sys/App.scss';

class App extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <MuiThemeProvider theme={muiTheme}>
          <CssBaseline />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
              <Routes />
        </MuiThemeProvider>
      </>
    )
  }
}

export default App;